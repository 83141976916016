@import '../../../../scss/theme-bootstrap';

// Main Navigation - sections
.header-nav-section {
  &__section {
    @include typography-body-text();
    position: relative;
    padding: 0;
    height: 100%;
    width: auto;
    float: $ldirection;
    display: flex;
    align-items: center;
    color: $color-white;
    .header-nav-section__trigger:checked + & {
      background-color: $color-core-black;
      display: flex;
      @include breakpoint($landscape-only) {
        background-color: transparent;
        overflow-y: inherit;
        display: flex;
        top: auto;
        z-index: auto;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked)
      ~ div
      .header-nav-section__trigger:not(:checked)
      + & {
      display: flex;
      @include breakpoint($landscape-only) {
        display: flex;
      }
    }
    &-label {
      @include gnav-main-link();
      font-size: $nav-label-font-size;
      &--text {
        cursor: pointer;
        text-decoration: none;
        text-transform: none;
        height: auto;
        box-shadow: 0 1px 0 0 transparent;
        @include breakpoint($landscape-only) {
          line-height: 1.3;
        }
        &:hover {
          color: $color-core-black;
          text-decoration: none;
          box-shadow: 0 1px 0 0 $color-core-black;
          .active-gnav & {
            color: $color-white;
          }
          .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
            color: $color-white;
            fill: $color-white;
            box-shadow: 0 1px 0 0 $color-white;
          }
        }
        .header-nav-section__trigger:checked ~ .header-nav-section__section & {
          display: flex;
          @include breakpoint($landscape-only) {
            display: flex;
          }
        }
        .site-header-formatter__search-icon {
          display: inline-flex;
          margin-#{$ldirection}: 0;
          margin-#{$rdirection}: 5px;
          height: 15px;
          width: 13px;
        }
      }
      .site-header-formatter__trigger-reset:not(:checked)
        ~ .site-header-formatter__sections
        .header-nav-section__trigger:checked
        + .header-nav-section__section
        & {
        box-shadow: 0 1px 0 0 $color-white;
        color: $color-white;
        fill: $color-white;
        text-decoration: none;
      }
      .active-gnav & {
        color: $color-white-op50;
        z-index: 1000;
      }
      .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
        color: $color-white-op50;
        fill: $color-white-op50;
        z-index: 1000;
        @include breakpoint($landscape-only) {
          animation: fadeInMainLink 0.7s cubic-bezier(0.5, 0, 0.5, 1);
        }
      }
    }
  }
  &__section-wrapper {
    flex: 0 0 auto;
    @include breakpoint($landscape-only) {
      flex: 0 1 auto;
    }
    padding-#{$rdirection}: $navbar-main-links-gap;
    &:last-child {
      padding-#{$rdirection}: 0;
    }
  }
  &__section-toggle {
    display: none;
    @include breakpoint($landscape-only) {
      display: block;
      position: fixed;
      #{$ldirection}: calc(
        #{$gnav-first-panel-width} - #{$container-pad-landscape} - #{$gnav-icon--inline}
      );
      top: calc(#{$container-pad-landscape} / 2);
      z-index: $gnav-fixed-z-index;
    }
    @include breakpoint($medium-landscape-only) {
      #{$ldirection}: calc(#{$gnav-first-panel-width} - 30px - #{$gnav-icon--inline});
    }
    svg {
      height: $gnav-icon--inline;
      width: $gnav-icon--inline;
      fill: $color-white;
    }
    .gnav-second-panel & {
      #{$ldirection}: $gnav-first-panel-width + $gnav-second-panel-width - 11%;
      animation: fadeInClose 2s;
    }
    .search-panel-visible & {
      #{$ldirection}: $gnav-search-panel-width - $container-pad-landscape - $gnav-icon--inline;
    }
  }
  &__section-content {
    @include transition(
      #{$ldirection} $gnav-panel-animation-duration $gnav-panel-animation-timing-function,
      visibility $gnav-panel-animation-duration $gnav-panel-animation-timing-function
    );
    background-color: $color-core-black;
    display: flex;
    #{$ldirection}: -100%;
    width: 100%;
    height: calc(100% - 129px);
    position: fixed;
    overflow: scroll;
    top: $gnav-panel-content-top-mob;
    flex-direction: column;
    z-index: 99;
    scrollbar-color: $color-white $color-core-black;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-white;
      border-radius: 20px;
    }
    @include breakpoint($landscape-only) {
      @include transition(opacity 0.7s cubic-bezier(0.5, 0, 0.5, 1) 0.4s);
      #{$ldirection}: 0;
      z-index: $gnav-fixed-z-index - 1;
      top: 0;
      max-width: $gnav-max-width;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      width: $gnav-first-panel-width;
      height: 100%;
      background: transparent;
    }
    html:not(.active-gnav) .site-header-formatter--sticky & {
      transition: none;
      @include breakpoint($landscape-only) {
        @include transition(opacity 0.7s cubic-bezier(0.5, 0, 0.5, 1) 0.4s);
      }
    }
    .search-panel-visible & {
      @include breakpoint($landscape-only) {
        width: $gnav-search-panel-width;
      }
    }
    .header-offers-banner-hidden & {
      @include breakpoint($landscape-only) {
        top: 0;
      }
    }
    // expanded state
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      @include breakpoint($landscape-only) {
        opacity: 1;
        visibility: visible;
      }
    }

    .header-nav-section__section-search
      .header-nav-section__trigger:not(:checked)
      + .header-nav-section__section
      & {
      transition: none;
    }

    .site-header-formatter__mobile-trigger:checked
      ~ .site-header-formatter__sections
      .header-nav-section__section
      & {
      #{$ldirection}: 0;
      opacity: 0;
      visibility: hidden;
    }

    .site-header-formatter__mobile-trigger:checked
      ~ .site-header-formatter__sections
      .header-nav-section__trigger:checked
      + .header-nav-section__section
      & {
      @include transition(#{$ldirection} $gnav-panel-animation-duration ease-in-out);
      #{$ldirection}: 0;
      opacity: 1;
      visibility: visible;
    }
    .site-header-formatter__trigger-reset:checked
      ~ .site-header-formatter__sections
      .header-nav-section__section
      & {
      @include transition(
        #{$ldirection} $gnav-panel-animation-duration $gnav-panel-animation-timing-function
      );
      #{$ldirection}: -100%;
      opacity: 1;
      visibility: visible;
      @include breakpoint($landscape-only) {
        @include transition(
          visibility $gnav-panel-animation-duration ease,
          opacity $gnav-panel-animation-duration ease
        );
        #{$ldirection}: 0;
      }
    }
    .site-header-formatter__trigger-reset:checked
      ~ .site-header-formatter__sections
      .header-nav-section__trigger:not(:checked)
      + .header-nav-section__section
      & {
      @include transition(
        #{$ldirection} $gnav-panel-animation-duration $gnav-panel-animation-timing-function,
        opacity $gnav-panel-animation-duration ease
      );
      #{$ldirection}: -100%;
      opacity: 0;
      visibility: hidden;
    }
    &[aria-hidden='true'] {
      transition: none;
    }
  }
  &__section-content-group {
    padding: 0;
    @include breakpoint($landscape-only) {
      overflow: auto;
      margin-top: $header-nav-top-pos;
      height: calc(100% - 250px);
      width: 96%;
      scrollbar-color: $color-white $color-core-black;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-white;
      border-radius: 20px;
    }
  }
  &__section-main-link {
    @include breakpoint($landscape-only) {
      height: 100%;
      display: flex;
    }
    .header-nav-section__trigger:checked ~ .header-nav-section__section & {
      display: none;
      @include breakpoint($landscape-only) {
        display: flex;
      }
    }
  }
  &__mobile-menu-icon {
    position: absolute;
    #{$rdirection}: $gnav-mobile-hpad--outer;
    stroke-width: 20px;
    stroke: $color-core-black;
    fill: $color-core-black;
    height: $gnav-mobile-link-height;
    width: $gnav-icon--inline;
    @include breakpoint($landscape-only) {
      display: none;
    }
    &:focus,
    &:hover,
    &:active {
      fill: $color-core-black;
    }
    .header-nav-section__trigger:checked ~ .header-nav-section__section & {
      display: none;
    }
    // Arrow displays for mobile expanders only.
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      opacity: 0;
    }
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-back {
    @include gnav-mobile-back();
    display: none;
    .site-header-formatter__subsection-trigger-reset:checked
      ~ .site-header-formatter__sections
      .header-nav-section__trigger:checked
      + .header-nav-section__section
      & {
      display: none;
      align-items: center;
      @include breakpoint($landscape-only) {
        display: none;
      }
    }
    &-label {
      font-size: 12px;
    }
    &-icon {
      display: inline-block;
      fill: $color-core-black;
      opacity: 1;
      position: relative;
      width: $gnav-icon--inline;
      height: $gnav-mobile-link-height;
      stroke: $color-core-black;
      stroke-width: 20px;
      @include breakpoint($landscape-only) {
        background: none;
        display: none;
      }
      &:hover,
      &:active {
        fill: $color-core-black;
      }
    }
  }
  &__section-mobile-only {
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__mob-bottomlinks {
    &.site-header-formatter__links-container {
      display: flex;
      flex: 1 auto;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 2vh;
      padding-bottom: 80px;
      @include breakpoint($landscape-only) {
        display: none;
      }
      .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
        @include breakpoint($landscape-only) {
          display: none;
        }
      }
    }
  }
}

body {
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .header-nav-section {
      display: none;
    }
  }
}

@keyframes fadeInClose {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInMainLink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
